@font-face {
  font-family: 'Moons';
  src: local('Moons'), url(./fonts/Moons.ttf) format('truetype');
}

.App {
  text-align: center;
  font-family: Moons;
  background-color: #0e0e0f;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-pulse infinite 2s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scaleOpacity {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

.socialButton:hover {
  animation: scaleOpacity 1s infinite;
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.component-parent {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: scroll;
  border: none;
  z-index: 20;
}

.floatingCat {
  width: 600px;
  height: auto;
}

.moon1 {
  display: block;
  width: 40rem;
  height: 40rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%); /* Center the element */
  border-radius: 50%;
  background-image: url('https://www.solarsystemscope.com/textures/download/2k_moon.jpg');
  background-repeat: repeat-x;
  background-size: 110% 100%;
  opacity: 0.8;
  animation-name: moonRotation;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards !important;
  box-shadow: inset -10px 8px 6px -5px #ffffff, 
    inset 20px -20px 40px 30px rgba(0, 0, 0, 0.9), 7px -6px 14px rgba(255, 255, 255, 0.3215686275);
}

@keyframes moonRotation {
    0% {
		background-position: 0% 0%;
    border: 0px solid #00000000;
	}
	50% {
		background-position: 150% 0%;
    border: 0px solid #00000000;
	}
	100% {
		background-position: 300% 0%;
    border: 0px solid #00000000;
	}
}

.component-parent img {
  width: 50rem;
  height: auto;
}

/* Adjustments for tablet devices */
@media (max-width: 768px) {
  .component-parent img {
    width: 30rem;
  }
  .moon1 {
    width: 30rem;
    height: 30rem;
    transform: translate(-50%, -80%); /* Center the element */
  }
}

/* Adjustments for mobile devices */
@media (max-width: 480px) {
  .component-parent img {
    width: 20rem;
  }
  .moon1 {
    width: 20rem;
    height: 20rem;
    transform: translate(-50%, -90%); /* Center the element */
  }
}


.memes-page {
  padding: 20px;
  text-align: center;
  z-index: 10;
}

.heading {
  font-size: 2em;
  margin-bottom: 20px;
  color: #fff;
  z-index: 10;
}

.memes-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  z-index: 10;
  z-index: 10;
}

.photo-grid-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.meme-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  z-index: 10;
}

/* Tablet devices */
@media (min-width: 768px) {
  .memes-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .photo-grid-item img {
    width: 350px;
    height: auto;
    /* border-radius: 8px; */
  }
}

/* Desktop devices */
@media (min-width: 1024px) {
  .memes-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.socialButton:hover {
  transform: scale(1.1);
  opacity: 0.8;
  cursor: pointer;
}